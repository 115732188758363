/* poppins-400regular  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('Poppins-Regular.ttf') format('truetype');
}

/* poppins-400regularitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('Poppins RegularItalic'), local('Poppins-RegularItalic'),
    url('Poppins-Italic.ttf') format('truetype');
}

/* poppins-500medium  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('Poppins-Medium.ttf') format('truetype');
}

/* poppins-500mediumitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local('Poppins MediumItalic'), local('Poppins-MediumItalic'),
    url('Poppins-MediumItalic.ttf') format('truetype');
}

/* poppins-600semibold  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('Poppins-SemiBold.ttf') format('truetype');
}

/* poppins-600semibolditalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local('Poppins SemiBoldItalic'), local('Poppins-SemiBoldItalic'),
    url('Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* poppins-700bold  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('Poppins-Bold.ttf') format('truetype');
}

/* poppins-700bolditalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local('Poppins BoldItalic'), local('Poppins-BoldItalic'),
    url('Poppins-BoldItalic.ttf') format('truetype');
}
